<template>
    <div>
      <validation-observer ref="simpleRules">
        <b-form>
          <b-card-code title="إضافة تقرير">
            <b-row>
              <b-col md="3" xl="3">
                <b-form-group label-for="">
                  <label> تاريخ البداية</label>
                  <flat-pickr v-model="StartDate" class="form-control" />
                </b-form-group>
              </b-col>
              <b-col md="3" xl="3">
                <b-form-group label-for="">
                  <label>تاريخ النهاية</label>
                  <flat-pickr v-model="EndData" class="form-control" />
                </b-form-group>
              </b-col>
              <b-col  md="2" xl="2" style="margin-left: 70px;">
                <b-button variant="purple" @click="SaveDocument" >
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle">توليد التقرير</span>
                </b-button>
              </b-col>
              <b-col  md="2" xl="2">
                <b-button variant="purple" @click="exportExcel">
                  <feather-icon icon="PlusIcon" class="mr-50" />
                  <span class="align-middle"> استخراج البيانات </span>
                </b-button>
              </b-col>
            </b-row>
          </b-card-code>
        </b-form>
      </validation-observer>
  
   <!-- <div >
   ddddddddd
      <v-grid
      class="revo-grid"
  
     theme="compact"
        :source="rows"
        :columns="columns"
  
      ></v-grid>
  
    </div> -->
    <b-row>
    <b-col></b-col>
     
              </b-row>
          <b-table-simple hover small caption-top responsive id="sampletable">
  
            <colgroup>
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
              <col />
            </colgroup>
            <colgroup>
              <col />
              <col />
            </colgroup>
            <b-thead head-variant="dark">
  
              <b-tr>
  
  
               <b-th colspan="6" style=" color:#000"></b-th>
                <b-th colspan="3" style=" color:#000">Total</b-th>
                <b-th colspan="6" style="  color:#000">Age Cohorts</b-th>
                <b-th colspan="11" style="  color:#000">Profile</b-th>
              </b-tr>
              <b-tr>
               <b-th colspan="5" style=" background-color: #deeaf6;  color:#000"></b-th>
                <b-th colspan="1" style=" background-color: #deeaf6; color:#000">Sub Category</b-th>
                <b-th colspan="1" style=" background-color: #deeaf6; color:#000">Families</b-th>
                <b-th colspan="3" style=" background-color: #deeaf6; color:#000">individuals</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">18</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">18-59</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">>60</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">IDP</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">Host Community</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">IDP retunee</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">Refuge retunee</b-th>
                <b-th colspan="2" style=" background-color: #deeaf6; color:#000">Refuge/asylum seeker</b-th>
              </b-tr>
              <b-tr>
  
                 <b-th colspan="3" style=" color:#000">Type</b-th>
                <b-th colspan="2" style="  color:#000">Main Service</b-th>
                 <b-th colspan="1" style="  color:#000">Sub Service</b-th>
                <b-th colspan="1" style=" background-color: #deeaf6;  color:#000">Number</b-th>
                <b-th style=" background-color: #c00000; color:#000 ">M & F</b-th>
                <b-th style=" background-color: #5b9bd5; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #5b9bd5; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #5b9bd5; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #5b9bd5; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #5b9bd5 ;color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #5b9bd5; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #5b9bd5; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #5b9bd5; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                <b-th style=" background-color: #; color:#000">M</b-th>
                <b-th style=" background-color: #ff99cc; color:#000">F</b-th>
                  <!-- <b-th style=" background-color: #5b9bd5; color:#000">M</b-th> -->
              </b-tr>
            </b-thead>
            <b-tbody
              v-for="(item, i) in Result"
              :key="i"
            >
              <b-tr>
              <b-th >{{ item.nnn}}</b-th>
               <b-th ></b-th>
               <b-th ></b-th> <b-th ></b-th> 
               
               <b-td>{{ item.main_services_name}}</b-td>
                  <b-td>{{ item.proposal_services_name}}</b-td>
  
                  <b-td>{{ item.families_count }}</b-td>
  
                <b-td>{{ item.individual_count }}</b-td>
                <!-- <b-td>{{ item.families_count }}</b-td>
                <b-td>{{ item.persons_count }}</b-td> -->
                <b-td>{{ item.Male }}</b-td>
                <b-td>{{ item.Female}}</b-td>
                <b-td>{{ item.age_range_0_M}}</b-td>
                <b-td>{{ item.age_range_0_F }}</b-td>
                <b-td>{{ item.age_range_1_M}}</b-td>
                <b-td>{{ item.age_range_1_F}}</b-td>
                <b-td>{{ item.age_range_2_M}}</b-td>
                    <b-td>{{ item.age_range_2_F}}</b-td>
                    <b-td>{{ item.a_M}}</b-td>
                <b-td>{{ item.a_F }}</b-td>
                <b-td>{{ item.b_M}}</b-td>
                <b-td>{{ item.b_F}}</b-td>
                <b-td>{{ item.c_M}}</b-td>
                    <b-td>{{ item.c_F}}</b-td>
                     <b-td>{{ item.d_M}}</b-td>
                <b-td>{{ item.d_F}}</b-td>
                <b-td>{{ item.e_M}}</b-td>
                    <b-td>{{ item.e_F}}</b-td>
                <!-- <b-td>{{item1.weakness_name}}</b-td>  <b-td>{{item1.families_count}}</b-td> -->
              </b-tr>
  
  
            </b-tbody>
            <b-tfoot>
              <!-- <b-tr>
                <b-td colspan="7" variant="secondary" class="text-right">
                  Total Rows: <b>5</b>
                </b-td>
              </b-tr> -->
            </b-tfoot>
          </b-table-simple>
  
    </div>
  </template>
  
  <script>
  
  import "vue-good-table/dist/vue-good-table.css";
  import { VueGoodTable } from "vue-good-table";
  import vSelect from "vue-select";
  import flatPickr from "vue-flatpickr-component";
  import "flatpickr/dist/flatpickr.css";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import BCardCode from "@core/components/b-card-code/BCardCode.vue";
  import {
    BRow,
    BCol,
    BFormGroup,
    BInputGroupPrepend,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BDropdownItemButton,
    BTableSimple,
  } from "bootstrap-vue";
  import Cleave from "vue-cleave-component";
  // eslint-disable-next-line import/no-extraneous-dependencies
  import "cleave.js/dist/addons/cleave-phone.us";
  
  
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
  
  import {
    BFormDatepicker,
    BFormCheckbox,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
  } from "bootstrap-vue";
  import { jsontoexcel } from "vue-table-to-excel";
  import VGrid from  "@revolist/vue-datagrid";
  import * as XLSX from 'xlsx/xlsx.mjs';

  export default {
    name: "",
  
    components: {
  
      BDropdown,
      BThead,
      BTr,
      BTh,
      BTd,
      BTbody,
      BTfoot,
      BTable,
      flatPickr,
      BDropdownItem,
      BDropdownItemButton,
      BFormInvalidFeedback,
      VueGoodTable,
      BForm,
      ValidationObserver,
      ValidationProvider,
      ToastificationContent,
      BCardCode,
      BButton,
      BFormInput,
      BFormFile,
      BFormGroup,
      BInputGroupPrepend,
      BInputGroup,
      BFormDatepicker,
      Cleave,
      BFormTextarea,
      vSelect,
      BFormCheckbox,
      BRow,
      BTableSimple,
  
      BCol,
       VGrid,
    },
  
    data() {
      return {
         columns: [{
            prop: "name",
            label: "Name",
          },
          {
            prop: "details",
            name: "Email",
        }],
        rows: [{
          name: "1",
          details: "Item 1",
        }],
       Result:[],
        fields: [
          { key: "date", sortable: true },
          {
            key: "customer",
            label: "Customer's Name",
            sortable: true,
          },
          {
            key: "items",
            label: "Item List",
            sortable: true,
          },
        ],
        documentType: [
          {
            value: 1,
            label: "العائلة",
          },
          {
            value: 2,
            label: "الأفراد",
          },
          {
            value: 3,
            label: "كلاهما",
          },
        ],
  
        pageLength: 3,
        dir: false,
  
        columns: [
          {
            label: "نوع الوثيقة ",
            field: "name",
          },
  
          {
            label: "الخيارات",
            field: "id",
            sortable: true,
          },
        ],
        rows: [],
        searchTerm: "",
        id: "",
        StartDate: "",
        EndData: "",
        gridApi: null,
        columnApi: null,
        defaultColDef: {
          sortable: true,
          filter: true,
          resizable: true,
          minWidth: 100,
          flex: 1,
        },
        popupParent: null,
        pinnedTopRowData: null,
        pinnedBottomRowData: null,
        rowData: null,
        Weaknesses:[],
        EndDare: "",
      };
    },
  
    created() {
  this.getWeakness()
    },
  
    methods: {
      exportExcel(){
   var wb = XLSX.utils.table_to_book(document.getElementById('sampletable'));
    XLSX.writeFile(wb, 'ServiceReport.xlsx');
      },
      SaveDocument() {
             this.$swal({
            title: "يتم جلب البيانات",
            icon: "success",
            confirmButtonText: "موافق",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
  
  
  
            let url = `/api/v1/stats/service_monthly_report?start_date=${this.StartDate}&end_date=${this.EndData}`;
                  this.$http.get(url).then((res) => {
                    //console.log(res.data)
                    let data=[{
                      nnn:'Statistcal OverView'
                    }]
                    // this.Result=res.data
  
   this.Result  = res.data.filter(data => data.service_name != 'لايوجد');
  
  
  
  
          //console.log("%cItem", "color: #a7f; font-weight: bold; font-size: 20px", this.Result);
            });
  
  
      },
      getWeakness(){
            let url = `api/v1/stats/all_weakness_en_name`;
                  this.$http.get(url).then((res) => {
                    //console.log(res.data.Weaknesses)
                    this.Weaknesses=res.data.Weaknesses
  
            });
      }
  
  
  
      //
    },
  };
  </script>
  
  <style scoped>
  label {
    color: rgba(112, 192, 203, 255);
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .btn-purple {
    color: white;
    background-color: #7e7a9f;
    margin-top: 25px;
  }
  .col-md-12.col-xl-3 {
      /* margin-right: auto; */
      /* margin-left: auto; */
      margin: 13px;
      text-align: end;
  }
    .revo-grid {
      height: 500px !important;
    }
    [dir] .table .thead-dark th{
      background-color: aquamarine;
    }
  span {
    font-weight: bold;
    font-family: "Cairo", sans-serif;
  }
  .align-left {
    left: 50px;
  }
  .but {
    top: 40px;
  }
  </style>
  